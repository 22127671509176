import React from "react";
import { Box, Stack, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { useTranslations } from "next-intl";
import AvailablePlans from "../AvailablePlans";


const StyledContainer = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(10)} ${theme.spacing(7)};
  position: relative;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: ${theme.spacing(5)} ${theme.spacing(3)};
  }
`
);

function PlansAndPayment() {
  const t = useTranslations("Home");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <StyledContainer className="section">
      <Box
        sx={{
          background: "linear-gradient(180deg, #7BFFF2 0%, rgba(47, 242, 64, 0.46) 100%)",
          width: isMobile ? "100%" : "1204px",
          height: isMobile ? "800px" : "1204px",
          opacity: 0.4,
          position: "absolute",
          top: "50%",
          left: "50%",
          borderRadius: "50%",
          transform: "translate(-50%, -50%)",
          filter: "blur(40px)",
          zIndex: 0,
        }}
      />
      <Stack width="100%" justifyContent="center" pb="96px">
        <Typography variant="sofiaSansHeader" fontSize={80} textAlign="center" pb={10}>{t("plan_title")}</Typography>
        <AvailablePlans hideTopBtn flexGrow={1} />
      </Stack>
    </StyledContainer>
  );
}

export default PlansAndPayment;