import { Stack, Typography, Box, useTheme, Button, styled, InputAdornment, TextField, useMediaQuery, Paper, InputBase, IconButton } from "@mui/material";
import { useTranslations } from "next-intl";
import { roboto } from "@/utis/fonts";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useCallback, useEffect, useState } from "react";
import Image from "next/image";
import { AnimatePresence, motion } from "framer-motion";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NavigationPanel from "../NavigationPanel";
import LoginPopup from "../Login/LoginPopup";
import { LoginType } from "../Login/LoginPopup/common";
import arbPhoto from "../../../public/images/Arb_Half.png";
import evFormulaScreen from "../../../public/images/EV_Half.png";
import oddsScreen from "../../../public/images/OddsScreen_Half.png";
import phoneOverlay from "../../../public/images/NoBG_Half.png";

const StyledWrapper = styled(Box)(
  ({ theme }) => `
    position: relative;
    padding-bottom: 40px;
  `
);

const StyledNavigationPanel = styled(NavigationPanel)`
  button {
    background-color: inherit !important;
  }
  a {
    background-color: inherit !important;
  }
`;

const StyledBody = styled(Stack)(
  () => `
    flex-direction: row;
    width: 100%;
  `
);

const StyledContent = styled(Box)(
  ({ theme }) => `
    flex-grow: 1;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
  
    height: 100vh,
    @media (max-width: ${theme.breakpoints.values.md}px) {
      justify-content: flex-start;
     
    }
  `,
);

const StyledFadedBorder = styled(Box)(
  ({ theme }) => `
    width: 1px;
    position: relative;
    background: linear-gradient(to bottom, ${theme.palette.divider}, ${theme.palette.primary.main});
  `
);

export const StyledTextField = styled(TextField)(({ theme }) => `
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    display: none;
  }
  background: ${theme.palette.grayScale.twelevepercent};
  border-radius: 8px;
  width: 100%;
  input {
    height: 16px;
    font-size: 16px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    input {
      height: 10px;
      font-size: 12px;
    }
  }
`);

function Top() {
  const theme = useTheme();
  const t = useTranslations("Home");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(event.target.value.trim());
  };

  const openLoginPopup = useCallback(() => {
    setShowLoginPopup(true);
  }, []);

  const closeLoginPopup = useCallback(() => {
    setShowLoginPopup(false);
  }, []);


  const images = [arbPhoto, evFormulaScreen, oddsScreen];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <StyledWrapper>
      <StyledBody>
        <StyledNavigationPanel hideBorder />
        <StyledFadedBorder />
        <StyledContent sx={{
          pt: isMobile ? "0px" : "80px",
        }}>
          <Box sx={{
            marginBottom: { xs: "250px", md: "0px" },
            paddingLeft: { xs: "15px", md: undefined },
            width: { xs: "100%", md: "fit-content" },
            zIndex: 500,
          }}>
            <Box mb={{ xs: 2, md: 3 }} mt={{ xs: 2, md: 0 }} maxWidth={{ xs: 300, md: 600 }}>
              <Typography
                variant="sofiaSansHeader"
                sx={{
                  fontSize: { xs: "67px", md: "122px" },
                  lineHeight: { xs: "60px", md: "105px" },
                }}
              >
                {t("title")}
              </Typography>
              <Typography variant="sofiaSansHeader" display="block" fontWeight={500}
                sx={{
                  fontSize: { xs: 20, md: 35 }, color: theme.palette.highlightText,
                  maxWidth: { xs: 180, md: 513 },
                  padding: { xs: "0px", md: "10px" },
                  lineHeight: { xs: "20px", md: "40px" }
                }} mt={2}>
                {t("subtitle")}
              </Typography>
            </Box>


            <Stack
              zIndex={2}
              direction="column"
              alignItems="start"
              gap={isMobile ? 1 : 0}
              maxWidth={{ xs: 180, md: 500 }}
              sx={{
                padding: { xs: "0px", md: "10px" },
              }}
            >
              <Typography
                fontSize={{ xs: 16, md: 20 }}
                sx={{ marginBottom: "10px", color: theme.palette.text.secondary }}
              >
                {t("description")}
              </Typography>
              {
                isMobile ? (
                  <><StyledTextField
                    variant="outlined"
                    placeholder="Email Address"
                    autoComplete="email"
                    value={emailAddress}
                    onChange={handleEmailChange}
                    sx={{
                      input: {
                        "&:-webkit-autofill": {
                          WebkitBoxShadow: `0 0 0 1000px ${theme.palette.grayScale.sixpercent} inset !important`,
                          transition: "background-color 5000s ease-in-out 0s",
                        },
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineIcon
                            sx={{
                              color: theme.palette.text.secondary,
                              fontSize: { xs: "18px", md: undefined },
                              marginBottom: { xs: "3px", md: undefined },
                            }} />
                        </InputAdornment>
                      ),
                    }} /><Button
                      variant="contained"
                      size="large"
                      fullWidth={false}
                      color="secondary"
                      onClick={openLoginPopup}
                      sx={{
                        width: "fit-content",
                        background: theme.palette.text.primary,
                        px: "30px",
                        py: "10px",
                        fontWeight: 600,
                        color: theme.palette.basicReverse,
                        textTransform: "none",
                        ml: { xs: undefined, md: "auto" },
                        mr: { xs: "auto", md: "0px" },
                        borderRadius: "8px",
                      }}
                    >
                      <span className={roboto.className}>{t("signup_now")}</span>
                    </Button></>
                ) : (

                  <Paper
                    component="form"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: isMobile ? 343 : 450,
                      backgroundColor: "#7E7E7E1F",
                      borderRadius: "12px",
                    }}
                    elevation={0}
                  >
                    <IconButton sx={{ p: "10px" }} aria-label="menu">
                      <MailOutlineIcon />
                    </IconButton>
                    <InputBase
                      sx={{ flex: 1 }}
                      placeholder="Email Address"
                      value={emailAddress}
                      onChange={handleEmailChange}
                      inputProps={{ "aria-label": "email address" }}
                    />
                    <Button
                      variant="contained"
                      size="large"
                      fullWidth={false}
                      color="secondary"
                      onClick={openLoginPopup}
                      disableElevation
                      sx={{
                        height: 50,
                        width: "fit-content",
                        background: theme.palette.text.primary,
                        px: 2,
                        borderRadius: "12px",
                        fontWeight: 600,
                        color: theme.palette.basicReverse,
                        textTransform: "none",
                      }}
                    >
                      <span className={roboto.className}> {t("signup_now")}</span>
                    </Button>
                    <LoginPopup open={showLoginPopup} onClose={closeLoginPopup} email={emailAddress} loginType={LoginType.SIGN_UP} />
                  </Paper>
                )
              }
            </Stack>
          </Box>

          <Box
            sx={{
              marginTop: { xs: "200px", md: "0px" },
              width: { xs: "60vw", md: "418px" },
              height: { xs: "440px", md: "640px" },
              marginLeft: { xs: "auto", md: "-40px" },
              position: { xs: "absolute", md: "relative" },
              right: { xs: "0px", md: undefined },
            }}
          >
            {/* Gradient Background Elements */}
            <Box sx={{ position: "absolute", zIndex: 10, pointerEvents: "none" }}>
              {/* Eclipse Gradient */}
              <Box
                sx={{
                  position: "absolute",
                  top: "20px",
                  left: isMobile ? "50px" : "-50px",
                  width: isMobile ? "200px" : "553px",
                  height: isMobile ? "400px" : "553px",
                  borderRadius: "50%",
                  background: "linear-gradient(180deg, #7BFFF2 0%, rgba(248, 255, 111, 0.46) 100%)",
                  WebkitBackgroundImage: "linear-gradient(180deg, #7BFFF2 0%, rgba(248, 255, 111, 0.46) 100%)",
                  filter: "blur(70px)",
                  WebkitFilter: "blur(70px)",
                  transform: "translate3d(0,0,0)",
                  WebkitTransform: "translate3d(0,0,0)",
                  willChange: "transform",
                }}
              />
              {/* Planet Gradient */}
              <Box
                sx={{
                  position: "absolute",
                  top: "0px",
                  left: isMobile ? "100px" : "50px",
                  width: isMobile ? "150px" : "405px",
                  height: isMobile ? "200px" : "405px",
                  borderRadius: "50%",
                  background: "linear-gradient(180deg, #2FF240 0%, rgba(245, 138, 37, 0) 100%, #6176A3 100%)",
                  WebkitBackgroundImage: "linear-gradient(180deg, #2FF240 0%, rgba(245, 138, 37, 0) 100%, #6176A3 100%)",
                  filter: "blur(70px)",
                  WebkitFilter: "blur(70px)",
                  transform: "translate3d(0,0,0)",
                  WebkitTransform: "translate3d(0,0,0)",
                  willChange: "transform",
                }}
              />
            </Box>

            {/* Static phone overlay that stays on top */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 200,
              }}
            >
              <Image
                src={phoneOverlay}
                alt="Phone Overlay"
                layout="fill"
                objectFit="contain"
              />
            </Box>

            {/* Animated screenshots underneath */}
            <AnimatePresence mode="sync">
              <motion.div
                key={currentImageIndex}
                initial={{ opacity: 0.6 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0.5 }}
                transition={{
                  duration: 0.4,
                  ease: "easeInOut"
                }}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  zIndex: 100,
                }}
              >
                <Image
                  src={images[currentImageIndex]}
                  alt={`Image ${currentImageIndex + 1}`}
                  layout="fill"
                  objectFit="contain"
                />
              </motion.div>
            </AnimatePresence>
          </Box>
        </StyledContent>
      </StyledBody>
      <Stack
        sx={{ marginTop: 10 }}
        direction="column"
        alignItems="center"
      >
        <Typography fontSize={isMobile ? 50 : 80} lineHeight="20px" variant="sofiaSansHeader">
          HERE’S HOW IT WORKS
        </Typography>
        <motion.div
          animate={{ y: [0, 10, 0] }}
          transition={{ repeat: Infinity, duration: 1, ease: "easeInOut" }}
        >
          <ArrowDropDownIcon
            sx={{
              fontSize: 80,
            }}
          />
        </motion.div>
      </Stack>
    </StyledWrapper>
  );
}

export default Top;