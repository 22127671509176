"use client";

import {
  ExploreTools,
  PlansAndPayment,
  Top,
  Community,
} from "@/components/WelcomePageSections";
import UTMModal from "@/components/UTM/UTMModal";

export default function Page() {
  return (
    <>
      <UTMModal />
      <Top />
      <ExploreTools />
      <PlansAndPayment />
      <Community />
    </>
  );
}
