import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Box, Button, IconButton, InputBase, Paper, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { roboto } from "@/utis/fonts";
import Image from "next/image";
import { useTranslations } from "next-intl";
import Marquee from "react-fast-marquee";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import gsap from "gsap";
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ICON_FILE_NAME_LIST } from "@/constants/IconsFileName";
import { useRouter } from "next/navigation";
import LoginPopup from "../Login/LoginPopup";
import { LoginType } from "../Login/LoginPopup/common";


const StyledWrapper = styled(Stack)(
  ({ theme }) => `
  align-items: center;
  min-height: 100vh;
  justify-content: center;
  width: 100%;
  position: relative;
  scroll-snap-type: y mandatory;
  overflow-x: clip;
  padding-top: ${theme.spacing(10)};
  padding-left: ${theme.spacing(8)};
  padding-right: ${theme.spacing(8)};
  padding-bottom: ${theme.spacing(8)};
  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding-top: ${theme.spacing(8)};
    padding-bottom: ${theme.spacing(5)};
     padding-left: ${theme.spacing(5.5)};
  padding-right: ${theme.spacing(5.5)};
  }
`,
);



const StyledSectionEven = styled(Stack)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 48px;
  height: 100vh;
    scroll-snap-align: start;
  min-height: 1000px;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    gap: ${theme.spacing(2)};
    flex-direction: column-reverse;
      height: 850px;
      min-height: 850px;
  }
`,
);

const StyledSectionOdd = styled(Stack)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 24px;
  height: 100vh;
  scroll-snap-align: start;
  min-height: 1000px;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    gap: ${theme.spacing(2)};
    flex-direction: column;
    height: 850px;
    min-height: 850px;
  }
`,
);


const StyledImageWrapper = styled(Box)(
  ({ theme }) => `
  position: relative;
  width: 550px;
  height: 700px;
  z-index: 50;
 
  @media (max-width: ${theme.breakpoints.values.md}px) {
   width:480px;
   height: 300px;
  }
`,
);

const StyledImageWrapperTools = styled(Box)(
  ({ theme }) => `
  position: relative;
  width: 700px;
  height: 500px;
  z-index: 50;
  @media (max-width: ${theme.breakpoints.values.md}px) {
   width:480px;
   height: 300px;
  }
`,
);

const StyledImageWrapperLaptop = styled(Box)(
  ({ theme }) => `
  position: relative;
  width: 1095px;
  height:680px;
  z-index: 50;
  margin-right: -500px;
     @media (min-width: 1450px) {
      margin-right: 0px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
   width:380px;
   height: 300px;
   margin-right: 0;
  }
`,
);

const StyledButton = styled(Button)(
  ({ theme }) => `
  height: 40px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: ${theme.palette.text.primary};
  padding-inline: ${theme.spacing(2)};
  font-weight: 800;
  gap: ${theme.spacing(1)};
  color: ${theme.palette.primary.main};
  text-transform: none;
  margin-top: ${theme.spacing(6)};
  @media (max-width: ${theme.breakpoints.values.md}px) {
    height: 44px;
      margin-top: 24px;
  }
`,
);



const StyledGalleryWrapper = styled(Stack)(() => ({
  alignItems: "flex-start",
  // width: "100%",
  "& .rfm-marquee": {
    minWidth: "unset",
  },
}));

const StyledImageRow = styled(Stack)(
  ({ theme }) => `
    flex-direction: row;
    margin-top: ${theme.spacing(4)};
    position: relative;
`,
);

const StyledImage = styled(Image)(
  ({ theme }) => `
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        margin-left: ${theme.spacing(6)};
        border-radius: 5px;
    `,
);


function OddsScreenSlideshow({ isMobileView = false }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    { src: "/images/OddsScreen1.png", alt: "Odds Screen 1" },
    { src: "/images/OddsScreen2.png", alt: "Odds Screen 2" },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Image
      src={images[currentImageIndex].src}
      alt={images[currentImageIndex].alt}
      layout="fill"
      objectFit={isMobileView ? "cover" : "contain"}
      style={isMobileView ? {
        maxWidth: 'none',
        objectPosition: 'left center'
      } : undefined}
    />
  );
}

gsap.registerPlugin(ScrollTrigger);

function UnderlineText(chunks: React.ReactNode) {
  return (
    <span style={{
      textDecoration: 'underline',
      textDecorationThickness: '4px',
      textUnderlineOffset: '8px'
    }}>
      {chunks}
    </span>
  );
}

function Italic(chunks: React.ReactNode) {
  return <i>{chunks}</i>;
}

const alternateSentenceColors = (text: string): (JSX.Element | string)[] => {
  const sentences = text.match(/[^.]+\.?/g) || [text];
  return sentences.map((sentence, index) => (
    <span key={index} style={{ color: index % 2 === 0 ? "#FFC56F" : undefined }}>
      {sentence.trim()}{' '}
    </span>
  ));
};


function highlightNumbers(text: string) {
  return text.split(/(\d+)/).map((segment, index) =>
    /\d+/.test(segment) ? (
      <span key={index} style={{ color: "#FFC56F" }}>
        {segment}
      </span>
    ) : (
      segment
    ),
  );
}


function ExploreTools() {
  const theme = useTheme();
  const t = useTranslations("Home");
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isUp1450 = useMediaQuery(theme.breakpoints.up(1450));
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLgDesktop = isUp1450 || isDownMd;
  const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>("");
  const router = useRouter();
  const oddsSectionTextRef = useRef<HTMLDivElement>(null);
  const arbitrageSectionTextRef = useRef<HTMLDivElement>(null);
  const evSectionTextRef = useRef<HTMLDivElement>(null);
  const toolsSectionTextRef = useRef<HTMLDivElement>(null);


  useLayoutEffect(() => {
    const textElements = [
      oddsSectionTextRef.current,
      arbitrageSectionTextRef.current,
      evSectionTextRef.current,
      toolsSectionTextRef.current
    ];

    textElements.forEach((element) => {
      if (element) {
        gsap.fromTo(
          element,
          {
            opacity: 0.3,
            y: 20
          },
          {
            opacity: 1,
            y: 0,
            scrollTrigger: {
              trigger: element,
              start: "top 85%",
              end: "top center",
              toggleActions: "play none none reverse",
              scrub: 0.5
            }
          }
        );
      }
    });

    const headings = document.querySelectorAll('[data-heading]');
    headings.forEach((heading) => {
      const isRightSide = heading.getAttribute('data-heading') === 'right';
      gsap.fromTo(
        heading,
        {
          x: isRightSide ? 50 : -50,
          opacity: 0.5
        },
        {
          x: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: heading,
            start: "top 75%",
            end: "top center",
            toggleActions: "play none none reverse",
            scrub: 0.8
          }
        }
      );
    });

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value.trim();
    setEmailAddress(email);
  };

  const openLoginPopup = useCallback(() => {
    setShowLoginPopup(true);
  }, []);

  const closeLoginPopup = useCallback(() => {
    setShowLoginPopup(false);
  }, []);

  return (
    <Box>
      <StyledWrapper ref={triggerRef}>

        <StyledSectionEven>
          <Stack justifyContent="center" alignItems="center">
            <Stack sx={{ textAlign: "left", maxWidth: 580, gap: 3 }}>
              <Typography fontSize={isMobile ? 50 : 80} variant="sofiaSansHeader" data-heading="left" lineHeight={isMobile ? "60px" : "80px"}>
                {t.rich("arbitrage_title", { u: UnderlineText })}
              </Typography>
              <Typography
                fontSize={isMobile ? 25 : 35}
                variant="sofiaSansHeader"
                ref={arbitrageSectionTextRef}
                lineHeight={isMobile ? "35px" : "35px"}
                fontWeight={500}>
                {alternateSentenceColors(t("arbitrage_info"))}
              </Typography>
            </Stack>
            <StyledButton
              variant="contained"
              size="small"
              fullWidth={false}
              color="secondary"
              onClick={() => router.push("/en/resources")} >
              <BookOutlinedIcon sx={{
                fontSize: 18,
              }} />
              <Typography fontSize={isMobile ? 12 : 14} variant="label" fontWeight={600}>
                {t("arbitrage_helper")}
              </Typography>

            </StyledButton>
          </Stack>
          <StyledImageWrapper>
            <Box
              sx={{
                background: `linear-gradient(180deg, #6176A3 0%, rgba(123, 255, 242, 0.46) 100%)`,
                WebkitBackgroundImage: `linear-gradient(180deg, #6176A3 0%, rgba(123, 255, 242, 0.46) 100%)`,
                width: isMobile ? '310px' : '560px',
                height: isMobile ? '310px' : '560px',
                opacity: 0.4,
                position: "absolute",
                top: "50%",
                left: "50%",
                borderRadius: "50%",
                transform: "translate(-50%, -50%) rotate(-75deg)",
                WebkitTransform: "translate(-50%, -50%) rotate(-75deg)",
                filter: "blur(80px)",
                WebkitFilter: "blur(80px)",
                zIndex: 0,
                willChange: "transform",
              }}
            />

            <Image
              src="/images/Arb_Home.png"
              alt="Arb Home Image"
              layout="fill"
              objectFit="contain"
            />
          </StyledImageWrapper>
        </StyledSectionEven>


        <StyledSectionOdd>
          <StyledImageWrapperLaptop sx={{
            ml: isLgDesktop ? "0" : "-500px",
            mr: 0
          }}>
            <Box
              sx={{
                background: "linear-gradient(180deg, #7BFFF2 0%, rgba(47, 242, 64, 0.46) 100%)",
                WebkitBackgroundImage: "linear-gradient(180deg, #7BFFF2 0%, rgba(47, 242, 64, 0.46) 100%)",
                width: isMobile ? '310px' : '720px',
                height: isMobile ? '410px' : '720px',
                opacity: 0.4,
                position: "absolute",
                top: "50%",
                left: "50%",
                borderRadius: "50%",
                transform: "translate(-50%, -50%) rotate(0deg)",
                WebkitTransform: "translate(-50%, -50%) rotate(0deg)",
                filter: "blur(70px)",
                WebkitFilter: "blur(70px)",
                zIndex: 0,
                willChange: "transform",
              }}
            />
            {
              isLgDesktop ? (
                <Image
                  src="/images/Ev_Home.png"
                  alt="EV Home Image"
                  layout="fill"
                  objectFit="contain"

                />
              ) : (
                <Image
                  src="/images/Ev_Home.png"
                  alt="EV Home Image"
                  layout="fill"
                  objectFit="cover"
                  style={{
                    maxWidth: 'none',
                    objectPosition: 'right center'
                  }}
                />
              )
            }
          </StyledImageWrapperLaptop>
          <Stack justifyContent="center" alignItems="center">
            <Stack sx={{ textAlign: "left", maxWidth: 580, gap: 3 }}>
              <Typography fontSize={isMobile ? 50 : 80} data-heading="right" variant="sofiaSansHeader" lineHeight={isMobile ? "60px" : "80px"}>
                {t.rich("postive_ev_title", { u: UnderlineText })}
              </Typography>
              <Typography fontSize={isMobile ? 25 : 35} variant="sofiaSansHeader" ref={evSectionTextRef} lineHeight={isMobile ? "35px" : "35px"} fontWeight={500}>
                {alternateSentenceColors(t("postive_ev_info"))}
              </Typography>
            </Stack>
            <StyledButton
              variant="contained"
              size="small"
              fullWidth={false}
              color="secondary"
              onClick={() => router.push("/en/expectedvalue")} >
              <Typography fontSize={isMobile ? 12 : 14} variant="label" fontWeight={600}>
                {t("ev_helper")}
              </Typography>
            </StyledButton>
          </Stack>

        </StyledSectionOdd>

        <StyledSectionEven>
          <Stack justifyContent="center" alignItems="center">
            <Stack sx={{ textAlign: "left", maxWidth: 590, gap: 3 }}>
              <Typography fontSize={isMobile ? 50 : 80} data-heading="left" variant="sofiaSansHeader" lineHeight={isMobile ? "60px" : "80px"}>
                {t.rich("odds_screen_title", { u: UnderlineText, i: Italic })}
              </Typography>
              <Typography
                ref={oddsSectionTextRef}
                fontSize={isMobile ? 25 : 35}
                variant="sofiaSansHeader"
                lineHeight={isMobile ? "35px" : "35px"} fontWeight={500}>
                {alternateSentenceColors(t("odds_screen_info"))}
              </Typography>
            </Stack>
            <StyledButton
              variant="contained"
              size="small"
              fullWidth={false}
              color="secondary"
              onClick={() => router.push("/en/odds-screen")} >
              <Typography fontSize={isMobile ? 12 : 14} variant="label" fontWeight={600}>
                {t("odds_screen_helper")}
              </Typography>
            </StyledButton>
          </Stack>
          <StyledImageWrapperLaptop >
            <Box
              sx={{
                background: "linear-gradient(180deg, #7BFFF2 0%, rgba(47, 242, 64, 0.46) 100%)",
                WebkitBackgroundImage: "linear-gradient(180deg, #7BFFF2 0%, rgba(47, 242, 64, 0.46) 100%)",
                width: isMobile ? '310px' : '720px',
                height: isMobile ? '410px' : '720px',
                opacity: 0.4,
                position: "absolute",
                top: "50%",
                left: "50%",
                borderRadius: "50%",
                transform: "translate(-50%, -50%) rotate(0deg)",
                WebkitTransform: "translate(-50%, -50%) rotate(0deg)",
                filter: "blur(70px)",
                WebkitFilter: "blur(70px)",
                zIndex: 0,
                willChange: "transform",
              }}
            />
            {
              isLgDesktop ? (
                <OddsScreenSlideshow />

              ) : (
                <OddsScreenSlideshow isMobileView />
              )}
          </StyledImageWrapperLaptop>
        </StyledSectionEven>

        <StyledSectionOdd>
          <StyledImageWrapperTools >
            <Box
              sx={{
                background: "linear-gradient(180deg, #6176A3 0%, rgba(123, 255, 242, 0.46) 100%)",
                WebkitBackgroundImage: "linear-gradient(180deg, #6176A3 0%, rgba(123, 255, 242, 0.46) 100%)",
                width: isMobile ? '310px' : '560px',
                height: isMobile ? '310px' : '560px',
                opacity: 0.4,
                position: "absolute",
                top: "50%",
                left: "50%",
                borderRadius: "50%",
                transform: "translate(-50%, -50%) rotate(-75deg)",
                WebkitTransform: "translate(-50%, -50%) rotate(-75deg)",
                filter: "blur(80px)",
                WebkitFilter: "blur(80px)",
                zIndex: 0,
                willChange: "transform",
              }}
            />
            <Image
              src="/images/Tools_Home.png"
              alt="tools Home Image"
              layout="fill"
              objectFit="contain"
            />
          </StyledImageWrapperTools>
          <Stack sx={{ textAlign: "left", maxWidth: 580, gap: 3 }}>
            <Typography fontSize={isMobile ? 50 : 80} data-heading="right" variant="sofiaSansHeader" lineHeight={isMobile ? "60px" : "80px"}>
              {t.rich("Tools_title", { u: UnderlineText })}
            </Typography>
            <Typography fontSize={isMobile ? 25 : 35} variant="sofiaSansHeader" ref={toolsSectionTextRef} lineHeight={isMobile ? "35px" : "35px"} fontWeight={500}>
              {alternateSentenceColors(t("Tools_info"))}
            </Typography>
          </Stack>
        </StyledSectionOdd>


        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            width: isMobile ? 343 : 450,
            backgroundColor: "#7E7E7E1F",
            borderRadius: "12px",
            mb: isMobile ? " 50px" : "150px",
            mt: isMobile ? 0 : "-100px"
          }}
          elevation={0}
        >
          <IconButton sx={{ p: "10px" }} aria-label="menu">
            <MailOutlineIcon />
          </IconButton>
          <InputBase
            sx={{ flex: 1 }}
            placeholder="Email Address"
            value={emailAddress}
            onChange={handleEmailChange}
            inputProps={{ "aria-label": "email address" }}
          />
          <Button
            variant="contained"
            size="large"
            fullWidth={false}
            color="secondary"
            onClick={openLoginPopup}
            disableElevation
            sx={{
              height: 50,
              width: "fit-content",
              background: theme.palette.text.primary,
              px: 2,
              borderRadius: "12px",
              fontWeight: 600,
              color: theme.palette.basicReverse,
              textTransform: "none",
            }}
          >
            <span className={roboto.className}> {t("explore_btn")}</span>
          </Button>
          <LoginPopup open={showLoginPopup} onClose={closeLoginPopup} email={emailAddress} loginType={LoginType.SIGN_UP} />
        </Paper>

        <Typography variant="sofiaSansHeader" fontSize={isMobile ? 25 : 35} fontWeight={500}>{highlightNumbers(t("explore_desc"))}</Typography>
        <StyledGalleryWrapper>
          <Box>
            {Array.from({ length: 3 }).map((_, index) => (
              <Marquee direction={index % 2 === 0 ? "right" : "left"} key={index}>
                <StyledImageRow key={index}>
                  {ICON_FILE_NAME_LIST.sort(() => Math.random() - 0.5).map((icon) => (
                    <StyledImage src={`/betsites/icons/${icon}`} alt={icon} width={48} height={48} key={`${index}_${icon}`} />
                  ))}
                </StyledImageRow>
              </Marquee>
            ))}
          </Box>
        </StyledGalleryWrapper>
      </StyledWrapper>
    </Box>
  );
}

export default ExploreTools;