import React, { useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Image from "next/image";

function Community() {

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll<HTMLImageElement>(".parallax-img");
      const scrollPosition = window.scrollY;

      elements.forEach((element) => {
        const speed = parseFloat(element.getAttribute("data-speed") || "0");
        const maxTranslation = 100;

        const rect = element.getBoundingClientRect();
        const elementTop = rect.top + scrollPosition;
        const elementVisible = window.innerHeight;

        if (elementTop < scrollPosition + elementVisible &&
          elementTop + rect.height > scrollPosition) {
          const yPos = (scrollPosition - elementTop) * speed * 2;
          const limitedYPos = Math.min(Math.max(yPos, -maxTranslation), maxTranslation);
          // Create a new CSSStyleDeclaration instead of modifying the element directly
          const newTransform = `translate3d(0, ${limitedYPos}px, 0)`;
          requestAnimationFrame(() => {
            element.style.setProperty('transform', newTransform);
          });
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box sx={{
      height: { xs: "500px", md: "1200px" },
      maxWidth: { xs: 600, md: 1320 },
      margin: "0 auto",
      overflow: "hidden",
      position: "relative"
    }}>
      <Stack width="100%" justifyContent="center">
        <Typography
          variant="sofiaSansHeader"
          fontSize={{ xs: 40, md: 80 }}
          textAlign="center"
          width="100%"
          pb={{ xs: 5, md: 10 }}
        >
          RESULTS PROVEN
        </Typography>
      </Stack>

      <Box sx={{
        position: "relative",
        height: { xs: '800px', md: '900px' },
        width: "100%",
        mt: { xs: 2, md: 10 },
        transform: {
          xs: 'translate3d(0,0,0) scale(0.45)',
          sm: 'translate3d(0,0,0) scale(0.45)',
          md: 'translate3d(0,0,0) scale(1)'
        },
        WebkitTransform: {
          xs: 'translate3d(0,0,0) scale(0.45)',
          sm: 'translate3d(0,0,0) scale(0.45)',
          md: 'translate3d(0,0,0) scale(1)'
        },
        transformOrigin: 'top left',
        WebkitTransformOrigin: 'top left',
        mx: "auto",
        ml: { xs: -8, md: 0 },
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <Box
          sx={{
            background: "linear-gradient(180deg, #6176A3 0%, rgba(123, 255, 242, 0.46) 100%)",
            WebkitBackgroundImage: "linear-gradient(180deg, #6176A3 0%, rgba(123, 255, 242, 0.46) 100%)",
            width: 767,
            height: 1044,
            opacity: 0.4,
            position: "absolute",
            top: "50%",
            left: "50%",
            borderRadius: "50%",
            transform: "translate3d(-50%, -50%, 0) rotate(-75deg)",
            WebkitTransform: "translate3d(-50%, -50%, 0) rotate(-75deg)",
            filter: "blur(70px)",
            WebkitFilter: "blur(70px)",
            zIndex: -10,
            willChange: "transform",
          }}
        />


        {/* Layer 1 - Strongest Movement (speed: 0.12) */}
        <Image
          src="/images/testimonials/testimonial_1.png"
          alt="Testimonial 1"
          data-speed="0.12"
          className="parallax-img"
          style={{
            position: "absolute",
            left: "80px",
            top: 0,
            transition: "transform 0.1s linear",
            WebkitTransition: "transform 0.1s linear",
            zIndex: 2
          }}
          width={492}
          height={229}
        />

        <Image
          src="/images/testimonials/testimonial_5.png"
          alt="Testimonial 5"
          data-speed="0.12"
          className="parallax-img"
          style={{
            position: "absolute",
            top: "235px",
            left: 0,
            transition: "transform 0.1s linear",
            WebkitTransition: "transform 0.1s linear",
            zIndex: 2
          }}
          width={524}
          height={463}
        />

        <Image
          src="/images/testimonials/testimonial_7.png"
          alt="Testimonial 7"
          data-speed="0.12"
          className="parallax-img"
          style={{
            position: "absolute",
            top: "300px",
            left: 700,
            transition: "transform 0.1s linear",
            WebkitTransition: "transform 0.1s linear",
            zIndex: 2
          }}
          width={617}
          height={438}
        />

        {/* Layer 2 - Medium Movement (speed: 0.08) */}
        <Image
          src="/images/testimonials/testimonial_3.png"
          alt="Testimonial 3"
          data-speed="0.08"
          className="parallax-img"
          style={{
            position: "absolute",
            top: "185px",
            left: "560px",
            transition: "transform 0.1s linear",
            WebkitTransition: "transform 0.1s linear",
            zIndex: 1
          }}
          width={354}
          height={419}
        />

        <Image
          src="/images/testimonials/testimonial_9.png"
          alt="Testimonial 9"
          data-speed="0.08"
          className="parallax-img"
          style={{
            position: "absolute",
            top: "88%",
            left: 780,
            transition: "transform 0.1s linear",
            WebkitTransition: "transform 0.1s linear",
            zIndex: 1
          }}
          width={537}
          height={128}
        />

        <Image
          src="/images/testimonials/testimonial_10.png"
          alt="Testimonial 10"
          data-speed="0.08"
          className="parallax-img"
          style={{
            position: "absolute",
            top: "710px",
            left: "20px",
            transition: "transform 0.1s linear",
            WebkitTransition: "transform 0.1s linear",
          }}
          width={555}
          height={139}
        />

        {/* Layer 3 - Subtle Movement (speed: 0.04) */}
        <Image
          src="/images/testimonials/testimonial_2.png"
          alt="Testimonial 2"
          data-speed="0.04"
          className="parallax-img"
          style={{
            position: "absolute",
            top: 40,
            left: "520px",
            transition: "transform 0.1s linear",
            WebkitTransition: "transform 0.1s linear",
            zIndex: 0
          }}
          width={295}
          height={332}
        />

        <Image
          src="/images/testimonials/testimonial_4.png"
          alt="Testimonial 4"
          data-speed="0.04"
          className="parallax-img"
          style={{
            position: "absolute",
            top: 0,
            left: 840,
            transition: "transform 0.1s linear",
            WebkitTransition: "transform 0.1s linear",
            zIndex: 0
          }}
          width={452}
          height={472}
        />

        <Image
          src="/images/testimonials/testimonial_6.png"
          alt="Testimonial 6"
          data-speed="0.04"
          className="parallax-img"
          style={{
            position: "absolute",
            top: "400px",
            left: "410px",
            transition: "transform 0.1s linear",
            WebkitTransition: "transform 0.1s linear",
            zIndex: -1
          }}
          width={426}
          height={100}
        />

        <Image
          src="/images/testimonials/testimonial_8.png"
          alt="Testimonial 8"
          data-speed="0.04"
          className="parallax-img"
          style={{
            position: "absolute",
            top: "65%",
            left: 600,
            transition: "transform 0.1s linear",
            WebkitTransition: "transform 0.1s linear",
          }}
          width={245}
          height={278}
        />
      </Box>
    </Box>
  );
}

export default Community;